<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <GrantsConstraintsPlanForm
      v-if="!isLoading"
      :grantsConstraintsPlan="grantsConstraintsPlan"
      v-on:addOrUpdateGrantsConstraintsPlans="addOrUpdateGrantsConstraintsPlans"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import GrantsConstraintsPlanForm from "@/components/settings/settingsSalary/grantsConstraintsPlans/GrantsConstraintsPlanForm.vue";
import GrantsConstraintsPlan from "@/models/settings/settingsSalary/grantsConstraintsPlans/GrantsConstraintsPlan";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    GrantsConstraintsPlanForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grantsConstraintsPlan: new GrantsConstraintsPlan(),
    };
  },
  props: ["grantsConstraintsPlansToken"],
  methods: {
    async getGrantsConstraintsPlansDetails() {
      this.isLoading = true;
      this.grantsConstraintsPlan.grantsConstraintsPlansToken =
        this.grantsConstraintsPlansToken;
      try {
        let response =
          await this.grantsConstraintsPlan.getGrantsConstraintsPlansDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.grantsConstraintsPlan.fillData(
            response.data.grantsConstraintsPlansData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "GrantsConstraintsPlans" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "GrantsConstraintsPlans" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateGrantsConstraintsPlans() {
      this.isLoading = true;
      try {
        let response =
          await this.grantsConstraintsPlan.addOrUpdateGrantsConstraintsPlans(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "GrantsConstraintsPlans" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getGrantsConstraintsPlansDetails();
  },
};
</script>
